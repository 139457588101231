<template>
  <div class="pagination">
    <el-pagination 
      background 
      layout="prev, pager, next" 
      :page-size="pageSize"
      v-model="pageNum"
      :total="number" 
      ref="page"
      v-if="showPage"
      :default-current-page="pageNum"
      @current-change="changePage"/>
  </div>
</template>

<script>
import merge from "webpack-merge"
export default {
  props: ['number', 'pageNumber', 'pageSize'],
  data() {
    return {
      pageNum: parseInt(this.$route.query.pager) || this.pageNumber,
      showPage: true
    }
  },
  mounted() {
    if(!this.$route.query.pager) {
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
    }
  },
  watch: {
    '$route.query.pager': {
      handler(n) {
        if(n) {
          this.showPage = false;
          this.pageNum = parseInt(n)
          this.$parent.pageNumber = n;
          this.$parent.showPage();
          let time = setInterval(() => {
            clearInterval(time)
            this.showPage = true;
          }, 50)
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
          // this.$refs.page.internalCurrentPage = parseInt(n);
        }
        
      },
      immediate: true
    }
  },
  methods: {
    changePage(page) {
      this.$parent.pageNumber = page;
      this.$router.replace({
        query: merge(this.$route.query, { pager: page }),
      });
      // this.$parent.showPage();
    }
  }
}
</script>

<style lang='scss'>
  .pagination  {
    margin: 30px 0;
    height: 32px;
  }
</style>